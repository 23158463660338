import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';
import resource from './coupon.json';
import AdminResource from 'rev.sdk.js/Generators/AdminResource';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import config from '../../../../data.json';
import _, {cloneDeep} from 'lodash';
import * as AppActions from '../../../AppActions';
import qs from 'query-string';
import XLSX from 'xlsx';

function CustomModal(props) {
  const {visible, setVisible} = props;

  return (
    <Ant.Modal
      destroyOnClose={true}
      open={visible}
      onCancel={() => setVisible(false)}
      title={null}
      footer={null}>
      <ModalContent {...props} />
    </Ant.Modal>
  );
}

function getAllMonthOptions() {
  const now = new Date();
  return [0, 1, 2, 3, 4, 5].map((mOffset) => {
    const dt = new Date(now);
    dt.setMonth(now.getMonth() - mOffset);
    return dt.toLocaleString('sv').slice(0, 7);
  });
}

function ModalContent(props) {
  const monthOptions = React.useRef(getAllMonthOptions()).current;
  const [selectedMonth, setSelectedMonth] = React.useState(monthOptions[0]);
  const [stats, setStats] = React.useState(null);
  const [selectedBirthMonth, setSelectedBirthMonth] = React.useState('01');
  const [birthStats, setBirthStats] = React.useState(null);
  const [downloadUrl, setDownloadUrl] = React.useState(null);

  async function fetchStats() {
    AppActions.setLoading(true);
    await AppActions.delay(600);
    setStats(null);
    try {
      const monthStr = parseInt(selectedMonth.split('-')[1], 10);
      const [resp] = await JStorage.aggregateDocuments('coupon', [
        {
          $match: {
            name: {$regex: `^${monthStr}月`},
            created: {$gte: new Date().getTime() - 300 * 24 * 3600000},
          },
        },
        {
          $project: {
            couponUsed: {
              $size: '$users',
            },
          },
        },
        {
          $group: {
            _id: 0,
            total: {
              $sum: 1,
            },
            totalUsed: {
              $sum: '$couponUsed',
            },
          },
        },
      ]);
      setStats(resp);
    } catch (ex) {
      window.alert('無法取得相關資訊');
    }
    AppActions.setLoading(false);
  }

  async function fetchBirthStats() {
    function toExcel(records) {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet([
        ['生日', '姓名', '電話', '電郵'],
        ...records.map((it) => {
          return [it.birthdate, it.name, it.phone, it.email];
        }),
      ]);
      XLSX.utils.book_append_sheet(wb, ws, '列表');
      const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'array',
        cellStyles: true,
        bookImages: true,
      });
      return URL.createObjectURL(
        new Blob([wbout], {type: 'application/octet-stream'}),
      );
    }

    AppActions.setLoading(true);
    await AppActions.delay(600);
    setBirthStats(null);
    try {
      const resp = await JStorage.aggregateDocuments('profile', [
        {
          $match: {
            birthdate: {$regex: `-${selectedBirthMonth}-`},
          },
        },
        {
          $project: {
            _id: 0,
            name: 1,
            birthdate: 1,
            phone: 1,
            email: 1,
          },
        },
      ]);
      setBirthStats(resp);
      setDownloadUrl(toExcel(resp));
    } catch (ex) {
      window.alert('無法取得相關資訊');
    }
    AppActions.setLoading(false);
  }

  return (
    <div style={{padding: 20}}>
      <div>
        <h3 style={{marginBottom: 20}}>已發送統計資訊查詢</h3>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <label>請選擇月份:</label>
          <Ant.Select
            style={{width: 120, marginLeft: 8}}
            value={selectedMonth}
            onChange={(value) => {
              setStats(null);
              setSelectedMonth(value);
            }}>
            {monthOptions.map((opt) => {
              return (
                <Ant.Select.Option value={opt} key={opt}>
                  {opt}
                </Ant.Select.Option>
              );
            })}
          </Ant.Select>
          <div style={{flex: 1}} />
          <Ant.Button onClick={() => fetchStats()}>確認</Ant.Button>
        </div>

        {stats && (
          <div style={{margin: '20px 0'}}>
            <div
              style={{
                fontWeight: 'bold',
              }}>{`共發出 ${stats.total} 份，已使用 ${stats.totalUsed} 份`}</div>
          </div>
        )}
      </div>

      <div style={{borderTop: '1px solid #ccc', paddingTop: 20, marginTop: 20}}>
        <h3 style={{marginBottom: 20}}>預估每月發送資訊查詢</h3>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <label>請選擇月份:</label>
          <Ant.Select
            style={{width: 120, marginLeft: 8}}
            value={selectedBirthMonth}
            onChange={(value) => {
              setBirthStats(null);
              setSelectedBirthMonth(value);
            }}>
            {[
              '01',
              '02',
              '03',
              '04',
              '05',
              '06',
              '07',
              '08',
              '09',
              '10',
              '11',
              '12',
            ].map((opt) => {
              return (
                <Ant.Select.Option value={opt} key={opt}>
                  {opt}
                </Ant.Select.Option>
              );
            })}
          </Ant.Select>
          <div style={{flex: 1}} />
          <Ant.Button onClick={() => fetchBirthStats()}>確認</Ant.Button>
        </div>

        {birthStats && (
          <div style={{marginTop: 20}}>
            <span
              style={{
                fontWeight: 'bold',
              }}>{`預計將發出 ${birthStats.length} 份`}</span>
            {downloadUrl && (
              <Ant.Button
                type="link"
                href={downloadUrl}
                target="_blank"
                download={`預計發送名單.xlsx`}>
                下載
              </Ant.Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function CustomButton(props) {
  const [visible, setVisible] = React.useState(false);

  return (
    <div style={{marginLeft: 10}}>
      <Ant.Button onClick={() => setVisible(true)}>統計資訊查詢</Ant.Button>
      <CustomModal visible={visible} setVisible={setVisible} />
    </div>
  );
}

function CustomCheckbox(props) {
  const {extQuery, onChange} = props;
  return (
    <>
      <label style={{marginRight: 5}}>* 僅列出已使用的優惠券</label>
      <input type="checkbox" checked={extQuery.checked} onChange={onChange} />
    </>
  );
}

function rewriteResourceSchema({resource, location}) {
  const nextResource = cloneDeep(resource);
  const params = qs.parse(location.search);
  let extQuery = {used: false};

  if (params.extra_q) {
    try {
      const _q = JSON.parse(decodeURIComponent(params.extra_q));
      extQuery = {
        ...extQuery,
        ..._q,
      };
    } catch (ex) {}
  }

  if (extQuery.used) {
    nextResource.predefinedQueries = [
      {
        users: {$exists: true, $ne: []},
      },
    ];
    console.log('DBG', nextResource.predefinedQueries);
  }

  nextResource.renderCreateButton = () => {
    return (
      <div
        style={{display: 'flex', alignItems: 'center', alignSelf: 'stretch'}}>
        <Ant.Button
          onClick={() => {
            AppActions.navigate(`/dashboard/coupons/?action=create`);
          }}>
          +
        </Ant.Button>
        <div style={{marginLeft: 10, display: 'flex', alignItems: 'center'}}>
          <CustomCheckbox
            location={location}
            extQuery={extQuery}
            onChange={(e) => {
              const data = {used: e.target.checked};
              AppActions.navigate(
                `/dashboard/coupons/?extra_q=${encodeURIComponent(
                  JSON.stringify(data),
                )}`,
              );
            }}
          />
        </div>
        <CustomButton />
      </div>
    );
  };
  return nextResource;
}

export default function CouponPage(props) {
  const [user] = useOutlet('user');
  const updatedResource = rewriteResourceSchema({
    resource,
    location: props.location,
  });

  return (
    <Wrapper>
      <AdminResource
        {...props}
        pageContext={{resource: updatedResource, config}}
        renderCustomAdminCol={({col, record}) => {
          if (col.customType === 'coupon_name') {
            return (
              <Ant.Button
                type="link"
                href={`/dashboard/coupons/?action=detail&id=${record.id}`}
                onClick={(e) => {
                  e.preventDefault();
                  AppActions.navigate(
                    `/dashboard/coupons/?action=detail&id=${record.id}`,
                  );
                }}>
                {record.name}
              </Ant.Button>
            );
          } else if (col.customType === 'used_count') {
            if (Array.isArray(record.users)) {
              return (
                <>
                  <div>Total: {record.users.length}</div>
                  {record.users.map((uid) => (
                    <div
                      style={{color: 'teal', cursor: 'pointer'}}
                      onClick={() => {
                        window.open(`/dashboard/members?id=${uid}`);
                      }}>
                      {uid}
                    </div>
                  ))}
                </>
              );
            }
            return 0;
          }
          return null;
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;
